<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Business rapport</div>
          <span class="subtitle-1 light-grey"
            >Filtrér rapporten efter behov. Som standard vises nuværende
            dag.</span
          >
        </div>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              outlined
              color="primary"
              @click="
                filtersTmp = JSON.parse(JSON.stringify(filters));
                filterDialog = true;
              "
            >
              <!--<v-icon small>fal fa-cog</v-icon>-->
              <v-icon small>fas fa-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtrér</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="stats"
          :loading="loading"
          hide-default-footer
          :items-per-page="10000"
        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.uuid">
                <td>
                  <router-link
                    :to="{
                      name: 'business',
                      params: { id: item.business ? item.business.uuid : '#'}
                    }"
                  >
                    {{ item.business ? item.business.display_name : '' }}
                  </router-link>
                </td>
                <td>{{ item.clicks }}</td>
                <td>{{ item.sales }}</td>
                <td>{{ item.conversion }}</td>
                <td>{{ item.value }}</td>
                <td>{{ item.commission }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="filterDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filtrér rapport
        </v-card-title>
        <v-card-text>
          <date-picker
            v-model="filtersTmp.start_date"
            label="Periode start"
            cancel-button
            reset-button
          ></date-picker>
          <date-picker
            v-model="filtersTmp.end_date"
            label="Periode slut"
            cancel-button
            reset-button
          ></date-picker>
          <v-flex class="mt-2">
            <influencer-autocomplete
              v-model="filtersTmp.influencer_uuid"
              :return-object="false"
            ></influencer-autocomplete>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              filters = cloneDeep(filtersTmp);
              filterDialog = false;
            "
          >
            Filtrér
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers.js";
import DatePicker from "@/components/common/filters/DatePicker";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import InfluencerAutocomplete from "../../autocompletes/InfluencerAutocomplete";
import { mapActions } from "vuex";

export default {
  components: { DatePicker, InfluencerAutocomplete },
  mixins: [search_filters_url],
  filters: { local_numbers },
  data: () => ({
    headers: [
      { text: "Virksomhed", value: "business.display_name", sortable: true },
      { text: "Klik", value: "clicks", sortable: true },
      { text: "Salg", value: "sales", sortable: true },
      { text: "Konvertering", value: "conversion", sortable: true },
      { text: "Omsætning", value: "value", sortable: true },
      { text: "Kommission", value: "commission", sortable: true }
    ],
    stats: [],
    filterDialog: false,
    filtersTmp: {},
    filters: {},
    loading: false
  }),
  watch: {
    filters: {
      handler: function() {
        this.stats = [];
        this.setUrlSearchFilters(this.filters);
        this.getStats();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", { loadStats: "getStats" }),
    getStats() {
      if (this.loading) {
        return null;
      }
      this.loading = true;
      const params = {
        ...this.filters,
        group: ["business"]
      };
      this.loadStats(params).then(stats => {
        this.stats = stats;
        this.loading = false;
      });
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();
    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment().format("YYYY-MM-DD");
      this.filters.end_date = this.$moment().format("YYYY-MM-DD");
    } else {
      this.getStats();
    }
  }
};
</script>
